import Keycloak from 'keycloak-js';
import store from './store';
const { keycloak_client_id } = store.getState();

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = Keycloak({
    realm: 'CSS',
    url: 'https://login.cssitconsulting.de/auth/',
    clientId: keycloak_client_id,
});

export default keycloak;
