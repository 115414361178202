export const logoNegative = [
    '690 134',
    `
<title>CAV2</title>
<g transform="matrix(3.01839,0,0,2.94828,-329.381,-267.441)">
<g transform="matrix(36,0,0,36,179.846,126.306)">
    <path d="M0.594,-0.498L0.662,-0.498C0.657,-0.535 0.646,-0.568 0.629,-0.597C0.611,-0.626 0.589,-0.65 0.563,-0.67C0.536,-0.689 0.507,-0.704 0.475,-0.714C0.442,-0.724 0.409,-0.729 0.375,-0.729C0.319,-0.729 0.27,-0.719 0.228,-0.699C0.185,-0.678 0.15,-0.651 0.122,-0.617C0.094,-0.582 0.073,-0.543 0.059,-0.498C0.045,-0.453 0.038,-0.406 0.038,-0.357C0.038,-0.308 0.045,-0.261 0.059,-0.216C0.073,-0.171 0.094,-0.132 0.122,-0.098C0.15,-0.064 0.185,-0.037 0.228,-0.017C0.27,0.004 0.319,0.014 0.375,0.014C0.417,0.014 0.455,0.007 0.49,-0.006C0.524,-0.019 0.554,-0.038 0.579,-0.063C0.604,-0.088 0.625,-0.118 0.641,-0.153C0.657,-0.188 0.667,-0.228 0.672,-0.272L0.604,-0.272C0.601,-0.24 0.593,-0.21 0.581,-0.183C0.569,-0.155 0.553,-0.131 0.534,-0.11C0.515,-0.089 0.492,-0.073 0.465,-0.061C0.438,-0.049 0.408,-0.043 0.375,-0.043C0.328,-0.043 0.287,-0.052 0.253,-0.07C0.219,-0.088 0.191,-0.112 0.17,-0.142C0.148,-0.171 0.132,-0.205 0.122,-0.243C0.111,-0.28 0.106,-0.318 0.106,-0.357C0.106,-0.396 0.111,-0.435 0.122,-0.472C0.132,-0.509 0.148,-0.543 0.17,-0.573C0.191,-0.602 0.219,-0.626 0.253,-0.644C0.287,-0.662 0.328,-0.671 0.375,-0.671C0.401,-0.671 0.426,-0.667 0.45,-0.66C0.473,-0.652 0.495,-0.641 0.515,-0.626C0.534,-0.611 0.551,-0.593 0.565,-0.572C0.578,-0.55 0.588,-0.525 0.594,-0.498Z" style="fill:rgb(0,161,255);fill-rule:nonzero;"/>
</g>
<g transform="matrix(36,0,0,36,205.19,126.306)">
    <path d="M0.173,-0.28L0.316,-0.646L0.454,-0.28L0.173,-0.28ZM0.281,-0.714L-0.006,-0L0.066,-0L0.152,-0.222L0.477,-0.222L0.564,-0L0.637,-0L0.357,-0.714L0.281,-0.714Z" style="fill:rgb(0,161,255);fill-rule:nonzero;"/>
</g>
<g transform="matrix(36,0,0,36,226.07,126.306)">
    <path d="M-0.006,-0.714L0.258,-0L0.337,-0L0.602,-0.714L0.53,-0.714L0.3,-0.075L0.298,-0.075L0.066,-0.714L-0.006,-0.714Z" style="fill:rgb(0,161,255);fill-rule:nonzero;"/>
</g>
<g transform="matrix(36,0,0,36,247.418,126.306)">
    <path d="M0.046,-0C0.048,-0.032 0.055,-0.061 0.068,-0.087C0.081,-0.112 0.097,-0.135 0.117,-0.157C0.136,-0.178 0.158,-0.197 0.183,-0.215C0.207,-0.233 0.232,-0.25 0.257,-0.267C0.282,-0.284 0.306,-0.301 0.33,-0.318C0.354,-0.335 0.376,-0.353 0.395,-0.372C0.413,-0.391 0.429,-0.413 0.44,-0.436C0.451,-0.459 0.457,-0.485 0.457,-0.514C0.457,-0.537 0.452,-0.559 0.443,-0.578C0.434,-0.596 0.421,-0.613 0.406,-0.627C0.39,-0.64 0.372,-0.651 0.352,-0.658C0.331,-0.665 0.31,-0.669 0.287,-0.669C0.256,-0.669 0.228,-0.664 0.205,-0.653C0.182,-0.642 0.162,-0.627 0.147,-0.608C0.132,-0.588 0.12,-0.566 0.113,-0.541C0.105,-0.515 0.101,-0.487 0.101,-0.457L0.063,-0.457C0.067,-0.541 0.087,-0.603 0.124,-0.643C0.16,-0.682 0.212,-0.702 0.281,-0.702C0.31,-0.702 0.338,-0.698 0.364,-0.691C0.39,-0.683 0.413,-0.671 0.432,-0.656C0.451,-0.64 0.467,-0.62 0.478,-0.597C0.489,-0.573 0.495,-0.545 0.495,-0.513C0.495,-0.472 0.486,-0.437 0.467,-0.406C0.448,-0.375 0.424,-0.346 0.395,-0.321C0.366,-0.296 0.334,-0.272 0.301,-0.25C0.267,-0.228 0.235,-0.206 0.206,-0.185C0.176,-0.163 0.151,-0.14 0.13,-0.117C0.109,-0.093 0.097,-0.067 0.094,-0.038L0.506,-0.038L0.506,-0L0.046,-0Z" style="fill:white;fill-rule:nonzero;"/>
</g>
</g>
`,
];
