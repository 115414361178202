import React from 'react';
import { CSpinner } from '@coreui/react';

const loadingIndicator = (
    <div
        style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
        }}
    >
        <CSpinner color="info" style={{ width: '4rem', height: '4rem' }} />
    </div>
);

export default loadingIndicator;
